import { sha256 } from "js-sha256";
const RSAKey = require("isomorphic-rsa");

const DATA_HEADER = "CYPIOS-CRYPTED";

const _crypto = (
  typeof window === "undefined" ? require("crypto").webcrypto : window.crypto
) as Crypto;
const _atob = (
  typeof window === "undefined" ? require("base-64").decode : window.atob
) as (data: string) => string;
const _btoa = (
  typeof window === "undefined" ? require("base-64").encode : window.btoa
) as (data: string) => string;

function _arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return _btoa(binary);
}

function _base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binary_string = _atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export const AESExport = async (key: CryptoKey): Promise<string> => {
  if (key.type === "private" || key.type === "public")
    throw new Error("Key is not symetric");
  const exportedKey = await _crypto.subtle.exportKey("raw", key);
  return _arrayBufferToBase64(exportedKey);
};

export const AESImport = async (key: string): Promise<CryptoKey> => {
  const keyBytes = _base64ToArrayBuffer(key);
  return await _crypto.subtle.importKey("raw", keyBytes, "AES-CBC", true, [
    "encrypt",
    "decrypt",
  ]);
};

export const AESKeygen = async (): Promise<CryptoKey> => {
  const aesAlgorithmKeyGen: AesKeyGenParams = {
    name: "AES-CBC",
    length: 256,
  };

  const aesKey = await _crypto.subtle.generateKey(aesAlgorithmKeyGen, true, [
    "encrypt",
    "decrypt",
  ]);
  return aesKey as CryptoKey;
};

export const AESEncrypt = async (plainText: string, aesKey: CryptoKey) => {
  const encoder = new TextEncoder();
  const clearDataArrayBufferView = encoder.encode(plainText);

  const aesAlgorithmEncrypt: AesCbcParams = {
    name: "AES-CBC",
    iv: _crypto.getRandomValues(new Uint8Array(16)),
  };

  const crypted = await _crypto.subtle.encrypt(
    aesAlgorithmEncrypt,
    aesKey,
    clearDataArrayBufferView
  );
  return `${_arrayBufferToBase64(
    aesAlgorithmEncrypt.iv as ArrayBuffer
  )}|${_arrayBufferToBase64(crypted)}`;
};

export const AESDecrypt = async (cryptedText: string, aesKey: CryptoKey) => {
  const split = cryptedText.split("|");
  if (split.length !== 2) throw new Error("Invalid input (bad structure)");

  const IV = _base64ToArrayBuffer(split[0]);
  if (new Uint8Array(IV).length != 16)
    throw new Error("Invalid initialization vector");

  const cryptedBytes = _base64ToArrayBuffer(split[1]);

  const aesAlgorithmDecrypt: AesCbcParams = {
    name: "AES-CBC",
    iv: IV,
  };

  const plainBytes = await _crypto.subtle.decrypt(
    aesAlgorithmDecrypt,
    aesKey,
    cryptedBytes
  );

  const encoder = new TextDecoder();
  const plainText = encoder.decode(plainBytes);

  return plainText;
};

export const RSAKeygen = (): {
  private: string;
  public: string;
} => {
  const bits = 1024;
  const exponent = "10001"; // must be a string. This is hex string. decimal = 65537
  console.log(RSAKey);
  const rsa = new RSAKey();
  rsa.generate(bits, exponent);
  const pub = rsa.getPublicString(); // return json encoded string
  const pri = rsa.getPrivateString(); // return json encoded string
  return { public: pub, private: pri };
};

export const RSAEncrypt = async (
  plainText: string,
  publicKey: string
): Promise<string> => {
  const rsa = new RSAKey();
  rsa.setPublicString(publicKey);
  const encrypted = rsa.encrypt(plainText);
  return encrypted;
};

export const RSADecrypt = async (
  cryptedText: string,
  privateKey: string
): Promise<string> => {
  const rsa = new RSAKey();
  rsa.setPrivateString(privateKey);
  const decrypted = rsa.decrypt(cryptedText); // decrypted == originText
  return decrypted;
};

export const CreateCryptoSession = async (
  privKey: string,
  permissions: { ssoId: string; key: string }[]
) => {
  if (typeof window === "undefined")
    throw new Error("Creating a session cannot be done in a server context");

  CloseCryptoSession();

  await Promise.all(
    permissions.map(async (userKey) => {
      console.log(userKey);
      const clearKey = await RSADecrypt(userKey.key, privKey);
      window.localStorage.setItem(`__key__${userKey.ssoId}`, clearKey);
    })
  );
};

export const CloseCryptoSession = () => {
  console.log("CLEARING SESSION");

  const keys = Object.keys(window.localStorage);

  keys.forEach((k) => {
    if (k.startsWith("__key__")) window.localStorage.removeItem(k);
  });
};

let UnprotectKeyCache: { ssoId: string; key: CryptoKey } | null = null;

export const UnprotectObject = async (cryptedObject: any): Promise<any> => {
  if (cryptedObject === null) return cryptedObject;
  if (cryptedObject === undefined) return cryptedObject;
  if (typeof cryptedObject === "number") return cryptedObject;
  if (typeof cryptedObject === "boolean") return cryptedObject;
  if (typeof cryptedObject === "bigint") return cryptedObject;
  if (typeof cryptedObject === "function") return cryptedObject;
  if (typeof cryptedObject === "symbol") return cryptedObject;

  if (typeof cryptedObject === "string") {
    if (!cryptedObject.startsWith("CYPIOS_ENCRYPTED:")) return cryptedObject;
    const split = cryptedObject.split(":");
    if (split.length != 3 || split[0] !== "CYPIOS_ENCRYPTED")
      throw new Error("Cannot decrypt data: Invalid structure");

    let aesKey: CryptoKey | null = null;

    if (UnprotectKeyCache?.ssoId === split[1]) {
      aesKey = UnprotectKeyCache.key;
    } else {
      const key = window.localStorage.getItem(`__key__${split[1]}`);
      if (!key) {
        console.log("THROW NO KEY " + split[1]);
        throw new Error(
          `Cannot decrypt data: You have no rights on user ${split[1]}`
        );
      }
      aesKey = await AESImport(key);
      UnprotectKeyCache = { ssoId: split[1], key: aesKey };
    }
    return await AESDecrypt(split[2], aesKey);
  }

  if (Array.isArray(cryptedObject)) {
    return await Promise.all(
      cryptedObject.map(async (u) => await UnprotectObject(u))
    );
  }

  if (typeof cryptedObject === "object") {
    const plainObj = {} as any;
    for (const key of Object.keys(cryptedObject)) {
      plainObj[key] = await UnprotectObject(cryptedObject[key]);
    }
    return plainObj;
  }
};

export const UnprotectObjectFromServer = async (
  cryptedObject: any,
  aesKey: CryptoKey
): Promise<any> => {
  if (!cryptedObject) return cryptedObject;

  if (typeof cryptedObject === "number") return cryptedObject;

  if (typeof cryptedObject === "string") {
    if (!cryptedObject.startsWith("CYPIOS_ENCRYPTED:")) return cryptedObject;
    const split = cryptedObject.split(":");
    if (split.length != 3 || split[0] !== "CYPIOS_ENCRYPTED")
      throw new Error("Cannot decrypt data: Invalid structure");

    return await AESDecrypt(split[2], aesKey);
  }

  if (Array.isArray(cryptedObject)) {
    return await Promise.all(
      cryptedObject.map(async (u) => await UnprotectObjectFromServer(u, aesKey))
    );
  }

  if (typeof cryptedObject === "object") {
    const plainObj = {} as any;
    for (const key of Object.keys(cryptedObject)) {
      plainObj[key] = await UnprotectObjectFromServer(
        cryptedObject[key],
        aesKey
      );
    }
    return plainObj;
  }
};

export const RandomOTP = () => Math.floor(100000 + Math.random() * 900000);

const peppers = Array.from({ length: 255 }).map((_e, i) => i.toString());

export const HashSalt = (str: string) => sha256(`${str}-CYPIOS`);

export const HashPepper = (str: string) =>
  sha256(`${str}${peppers[Math.floor(Math.random() * peppers.length)]}`);

export const CheckPepper = (str: string, hash: string) =>
  peppers.some((p) => sha256(`${str}${p}`) === hash);

export const KeyFromText = (str: string) =>
  _arrayBufferToBase64(
    new Uint8Array(sha256.array(`${str}-CYPIOS`).slice(0, 256))
  );
