import React, { ChangeEvent } from "react";

interface Props {
  setKey: Function;
}

const Key = (props: Props) => {
  const openKey = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) {
      alert("Choisissez un fichier");
      return;
    }
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target!.result;
      props.setKey(text as string);
    };
    reader.readAsText(e.target.files[0]);
  };

  return (
    <div>
      <p>Présentez la clef d'urgence</p>
      <input type="file" onChange={(e) => openKey(e)} />
    </div>
  );
};

export default Key;
