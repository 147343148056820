import React from "react";
import * as Crypto from "./encrypt.lib";
import { SSO } from "./const";
import CheckProPermissions from "./CheckProPermissions";
import { Button } from "@mui/material";
import openFakeSession from "./openFakeSession";
import EnableDisableUser from "./EnableDisableUser";

interface Props {
  token: string;
  emergencyKey: string;
  ssoId: string;
}

export interface Session {
  accessToken: string;
  refreshToken: string;
  refreshExpiration: string;
  accessExpiration: string;
  emergencyKey: string;
  symKey: CryptoKey;
  publicKey: string;
  ssoId: string;
  permissions: { ssoId: string; key: CryptoKey }[];
}

export const createSession = async (
  ssoId: string,
  token: string,
  emergencyKey: string
): Promise<Session | null> => {
  const res = await fetch(`${SSO}/sso/superadmin/emergency/data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ssoId: ssoId }),
  });
  if (!res.ok) {
    alert(res.statusText);
    localStorage.clear();
    window.location.reload();
    return null;
  }
  const json = await res.json();
  return {
    ...json,
    symKey: await Crypto.AESImport(
      await Crypto.RSADecrypt(json.emergencyKey, emergencyKey)
    ),
    permissions: await Promise.all(
      json.permissions.map(async (p: any) => {
        return {
          ssoId: p.target.ssoId,
          key: await Crypto.AESImport(
            await Crypto.RSADecrypt(p.target.emergencyKey, emergencyKey)
          ),
        };
      })
    ),
  };
};

export const useUserSession = (props: Props) => {
  const [session, setSession] = React.useState<Session | null>(null);

  React.useEffect(() => {
    if (!props.token || !props.emergencyKey) return;
    createSession(props.ssoId, props.token, props.emergencyKey).then((res) =>
      setSession(res)
    );
  }, [props]);
  return session;
};

const ProDetails = (props: Props) => {
  const session = useUserSession(props);

  React.useEffect(() => {
    if (!props.token || !props.emergencyKey) return;
    if (!session) return;

    // Ici on a une session du pro qu'on peut utiliser comme on veut

    // fetch(`${API}/pro/rgpd`, {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${session.accessToken}`,
    //   },
    // }).then(async (res) => {
    //   if (!res.ok) {
    //     alert(res.statusText);
    //     localStorage.clear();
    //     window.location.reload();
    //     return;
    //   }
    //
    //   const json = await res.json();
    //   setData(await Crypto.UnprotectObjectFromServer(json, session.symKey));
    // });
  }, [session, props]);

  if (!session) return <p>Création d'une session sécurisée...</p>;
  // if (!data) return <p>Chargement des données de l'utilisateur...</p>;

  return (
    <div>
      <CheckProPermissions
        {...props}
        permissions={session.permissions}
        publicKey={session.publicKey}
      />
      <Button onClick={() => openFakeSession(session)}>
        Créer une session sur l'interface pro
      </Button>
      <EnableDisableUser token={props.token} ssoId={props.ssoId} />
    </div>
  );
};

export default ProDetails;
