import React from "react";
import * as Crypto from "./encrypt.lib";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PatientDetails from "./PatientDetails";
import { SSO } from "./const";
import ProDetails from "./ProDetails";
import AdminDetails from "./AdminDetails";

interface Props {
  token: string;
  emergencyKey: string;
}

interface UserListEntry {
  ssoId: string;
  role: "pro" | "patient" | "admin";
  name: string;
  email: string;
}

const UserList = (props: Props) => {
  const [selected, setSelected] = React.useState<any | null>(null);
  const [users, setUsers] = React.useState<UserListEntry[] | null>(null);

  React.useEffect(() => {
    if (!props.token || !props.emergencyKey) return;
    fetch(`${SSO}/sso/superadmin/emergency/list`, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    }).then(async (res) => {
      if (!res.ok) {
        alert(res.statusText);
        localStorage.clear();
        window.location.reload();
        return;
      }
      const json = (await res.json()) as any[];
      setUsers(
        await Promise.all(
          json.map<Promise<UserListEntry>>(async (u: any) => ({
            email: u.email,
            ssoId: u.ssoId,
            role: u.role,
            name: await Crypto.RSADecrypt(u.emergencyName, props.emergencyKey),
          }))
        )
      );
    });
  }, [props]);

  if (selected?.role === "patient")
    return (
      <PatientDetails
        token={props.token}
        emergencyKey={props.emergencyKey}
        ssoId={selected.ssoId}
      />
    );
  if (selected?.role === "pro")
    return (
      <ProDetails
        token={props.token}
        emergencyKey={props.emergencyKey}
        ssoId={selected.ssoId}
      />
    );

  if (selected?.role === "admin")
    return (
      <AdminDetails
        token={props.token}
        emergencyKey={props.emergencyKey}
        ssoId={selected.ssoId}
      />
    );

  if (!users) return <p>Chargement des utilisateurs...</p>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>SSOID</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Nom</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Données</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <TableRow
              key={row.ssoId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.ssoId}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.role}
              </TableCell>
              <TableCell align="right">
                <IconButton color="secondary" onClick={() => setSelected(row)}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserList;
