import React from "react";
import CheckPermissions from "./CheckProPermissions";
import { useUserSession } from "./ProDetails";
import { Button } from "@mui/material";
import openFakeSession from "./openFakeSession";
import EnableDisableUser from "./EnableDisableUser";

interface Props {
  token: string;
  emergencyKey: string;
  ssoId: string;
}

const AdminDetails = (props: Props) => {
  const session = useUserSession(props);

  React.useEffect(() => {
    if (!props.token || !props.emergencyKey) return;
    if (!session) return;

    // Ici on a une session de l'admin qu'on peut utiliser comme on veut
  }, [session, props]);

  if (!session) return <p>Création d'une session sécurisée...</p>;

  return (
    <div>
      <CheckPermissions
        {...props}
        permissions={session.permissions}
        publicKey={session.publicKey}
      />
      <Button onClick={() => openFakeSession(session)}>
        Créer une session sur l'interface admin
      </Button>
      <EnableDisableUser token={props.token} ssoId={props.ssoId} />
    </div>
  );
};

export default AdminDetails;
