import React from "react";
import { Button, TextField } from "@mui/material";
import { SSO } from "./const";

interface Props {
  setToken: Function;
}

const Login = (props: Props) => {
  const [email, setEmail] = React.useState<string>("admin@cypios.fr");
  const [password, setPassword] = React.useState<string>();

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          fetch(`${SSO}/sso/superadmin/login`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, password }),
          }).then(async (res) => {
            if (!res.ok) alert("Error : " + res.statusText);

            const json = await res.json();

            localStorage.setItem("token", json.accessToken);
            localStorage.setItem("expiration", json.accessExpiration);
            props.setToken(json.accessToken);
          });
        }}
      >
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          type={"email"}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Mot de passe"
          variant="outlined"
          type={"password"}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" type={"submit"}>
          Se connecter
        </Button>
      </form>
    </div>
  );
};

export default Login;
