import { Session } from "./ProDetails";
import { AESExport } from "./encrypt.lib";

const openFakeSession = async (session: Session) => {
  window.open(
    `${process.env.REACT_APP_FRONT}/import?ssoId=${encodeURI(
      session.ssoId
    )}&accessToken=${encodeURI(session.accessToken)}&refreshToken=${encodeURI(
      session.refreshToken
    )}&accessExpiration=${encodeURI(
      session.accessExpiration
    )}&refreshExpiration=${encodeURI(session.refreshExpiration)}
    &keys=${encodeURI(
      (
        await Promise.all(
          session.permissions.map(
            async (p) => `${p.ssoId}$${await AESExport(p.key)}`
          )
        )
      ).join(";")
    )}`
  );
};

export const createPatientFakeSession = async (
  session: Session
): Promise<string> => {
  const permission = session.permissions[0];
  if (!permission) {
    console.error("User has no permisison: error");
    return "error";
  }
  return btoa(
    JSON.stringify({
      ...session,
      permissions: await Promise.all(
        session.permissions.map(async (p) => ({
          ...p,
          key: await AESExport(p.key),
        }))
      ),
    })
  );
};

export default openFakeSession;
