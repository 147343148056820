import React, { useState } from "react";
import { SSO } from "./const";
import { AESExport, RSAEncrypt } from "./encrypt.lib";
import { Box, Button, Typography } from "@mui/material";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Utils from "@admincypios/utils";
import { createSession } from "./ProDetails";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  token: string;
  emergencyKey: string;
}

interface Progress {
  tested: number;
  total: number;
  valid: number;
  invalid: number;
}

const fixPermissions = async (
  token: string,
  emergencyKey: string,
  usersToFix: any[],
  setProgess: (p: number) => void
) => {
  let progress = 0;

  for (const u of usersToFix) {
    const rawRes = await fetch(`${SSO}/sso/superadmin/fix/`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ssoId: u.ssoId,
        permissions: await Promise.all(
          u.final.map(async (p: any) => {
            console.log(u);
            const userSession = await createSession(p, token, emergencyKey);
            console.log(await AESExport(userSession?.symKey!));
            return {
              ssoId: p,
              authorizationKey: await RSAEncrypt(
                await AESExport(userSession?.symKey!),
                u.publicKey
              ),
            };
          })
        ),
      }),
    });
    if (!rawRes.ok) throw new Error("Erreur lors de l'ajustement");
    setProgess(++progress);
  }
};

const checkPermissions = async (
  token: string,
  emergencyKey: string,
  users: any[],
  setProgress: (p: Progress) => void,
  setUsersToFix: (u: any[]) => void
) => {
  const toFix = [];
  let tested = 0;
  let valid = 0;
  let invalid = 0;
  const total = users.length;

  for (const u of users) {
    const accountType = Utils.uuid.UUIDGetAccountType(u.ssoId);
    if (accountType === "superadmin") {
      tested++;
      valid++;
    } else {
      const rawRes = await fetch(`${SSO}/sso/superadmin/check/${u.ssoId}`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (!rawRes.ok) throw new Error("Erreur lors de la vérification");
      const res = await rawRes.json();
      if (res.missing?.length || res.unwanted?.length) {
        console.log(u);
        toFix.push({ ...u, ...res });
        invalid++;
      } else valid++;
      tested++;
    }
    setProgress({ tested, invalid, total, valid });
  }
  setUsersToFix(toFix);
};

const GlobalFix = (props: Props) => {
  const [checkProgress, setCheckProgress] = useState<Progress>();
  const [fixProgress, setFixProgress] = useState<number>();
  const [usersToFix, setUsersToFix] = useState<any[]>();

  const [users, setUsers] = useState<any[]>();

  console.log(usersToFix);

  React.useEffect(() => {
    if (!props.token || !props.emergencyKey) return;
    fetch(`${SSO}/sso/superadmin/emergency/list`, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    }).then(async (res) => {
      if (!res.ok) {
        alert(res.statusText);
        localStorage.clear();
        window.location.reload();
        return;
      }
      setUsers(await res.json());
    });
  }, [props]);

  if (!users) return <p>Chargement...</p>;
  if (checkProgress && !usersToFix)
    return (
      <div>
        <LinearProgressWithLabel
          value={(checkProgress.tested / checkProgress.total) * 100}
        />
        <p>
          testés : {checkProgress.tested} / {checkProgress.total}
        </p>
        <p>valides : {checkProgress.valid}</p>
        <p>invalides : {checkProgress.invalid}</p>
      </div>
    );
  if (usersToFix && !fixProgress)
    return (
      <div>
        <p>{usersToFix.length} utilisateurs posent problème</p>
        {usersToFix?.length ? (
          <Button
            onClick={() =>
              fixPermissions(
                props.token,
                props.emergencyKey,
                usersToFix,
                setFixProgress
              )
            }
          >
            Régler les problèmes de permissions
          </Button>
        ) : (
          <p>
            Aucun problème à régler sur les {checkProgress?.tested} utilisateurs
            testés
          </p>
        )}
      </div>
    );

  if (fixProgress && usersToFix)
    return (
      <div>
        <LinearProgressWithLabel
          value={(fixProgress / usersToFix.length) * 100}
        />
        <p>
          problèmes réglés : {fixProgress} / {usersToFix.length}
        </p>
        {fixProgress === usersToFix.length && (
          <p>Tous les probèmes sont réglés.</p>
        )}
      </div>
    );

  return (
    <div>
      <p>{users.length} utilisateurs chargés</p>
      <Button
        onClick={async () =>
          checkPermissions(
            props.token,
            props.emergencyKey,
            users,
            setCheckProgress,
            setUsersToFix
          )
        }
      >
        Vérifier les permissions
      </Button>
    </div>
  );
};

export default GlobalFix;
