import React, { useState } from "react";
import { Button } from "@mui/material";
import { SSO } from "./const";
import { AESExport, RSAEncrypt } from "./encrypt.lib";
import { createSession } from "./ProDetails";

interface Props {
  token: string;
  emergencyKey: string;
  ssoId: string;
  permissions: { key: CryptoKey; ssoId: string }[];
  publicKey: string;
}

interface AllowDto {
  ssoId: string;
  authorizationKey: string;
}

interface AdminFixPermissionsDto {
  ssoId: string;
  permissions: AllowDto[];
}

const CheckProPermissions = ({
  ssoId,
  token,
  permissions,
  publicKey,
  emergencyKey,
}: Props) => {
  const [errors, setErrors] = useState<null | {
    unwanted: string[];
    missing: string[];
    final: string[];
  }>(null);

  return (
    <div>
      {!errors ? (
        <Button
          onClick={async () => {
            const rawRes = await fetch(`${SSO}/sso/superadmin/check/${ssoId}`, {
              method: "POST",
              headers: {
                authorization: `Bearer ${token}`,
              },
            });
            setErrors(await rawRes.json());
          }}
        >
          Vérifier les permissions
        </Button>
      ) : (
        <div>
          <div>
            <p>Manquantes :</p>{" "}
            {errors?.missing?.map((e, i) => <p key={`${e}${i}`}>{e}</p>) ?? <p>Aucune</p>}
          </div>
          <div>
            <p>En trop :</p>{" "}
            {errors?.unwanted?.map((e, i) => <p key={`${e}${i}`}>{e}</p>) ?? <p>Aucune</p>}
          </div>

          <Button
            onClick={async () => {
              const rawRes = await fetch(`${SSO}/sso/superadmin/fix/`, {
                method: "POST",
                headers: {
                  authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ssoId: ssoId,
                  permissions: await Promise.all(
                    errors.final.map(async (p) => {
                      console.log(p);
                      const userSession = await createSession(
                        p,
                        token,
                        emergencyKey
                      );
                      console.log(await AESExport(userSession?.symKey!));
                      return {
                        ssoId: p,
                        authorizationKey: await RSAEncrypt(
                          await AESExport(userSession?.symKey!),
                          publicKey
                        ),
                      };
                    })
                  ),
                }),
              });
              if (rawRes.ok) {
                alert("Permissions ajustées");
                window.location.reload();
              } else {
                alert("Erreur")
              }
            }}
          >
            Ajuster les permissions
          </Button>
        </div>
      )}
    </div>
  );
};

export default CheckProPermissions;
