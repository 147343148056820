import React, {useEffect, useState} from "react";
import "./App.css";
import UserList from "./UserList";
import Login from "./Login";
import Key from "./Key";
import {Button} from "@mui/material";
import GlobalFix from "./GlobalFix";
import { EMERGENCY_KEY_OVERRIDE } from "./const";
// import * as Crypto from "encrypt.lib";

const getTokenCache = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;
  const expire = Number(localStorage.getItem("expiration"));
  if (!expire) return null;

  const now = new Date().getTime() / 1000;

  if (now > expire) return null;

  return token;
};

type Screen = "menu" | "list" | "globalfix" | "login" | "key";

function App() {
  const [token, setToken] = React.useState<string | null>(getTokenCache());
  const [emeregencyKey, setEmergencyKey] = React.useState<string | null>(EMERGENCY_KEY_OVERRIDE ?? null);
  const [screen, setScreen] = useState<Screen>("login");

  useEffect(() => {
    if (!token) setScreen("login");
    else if (!emeregencyKey) setScreen("key");
    else setScreen("menu");
  }, [token, emeregencyKey]);

  if (screen === "login") return <Login setToken={setToken} />;
  if (screen === "key") return <Key setKey={setEmergencyKey} />;

  if (screen === "menu")
    return (
      <div>
        <Button onClick={() => setScreen("list")}>
          Liste des utilisateurs
        </Button>
        <Button onClick={() => setScreen("globalfix")}>
          Verification générale des permissions
        </Button>
      </div>
    );
  if (screen === "list")
    return (
      <div>
        <UserList token={token!} emergencyKey={emeregencyKey!} />
      </div>
    );
  if (screen === "globalfix")
    return (
      <div>
        <GlobalFix token={token!} emergencyKey={emeregencyKey!} />
      </div>
    );
  return null;
}

export default App;
