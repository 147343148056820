import React from "react";
import * as Crypto from "./encrypt.lib";
import { API, PROTO } from "./const";
import { useUserSession } from "./ProDetails";
import { Button } from "@mui/material";
import { createPatientFakeSession } from "./openFakeSession";
import EnableDisableUser from "./EnableDisableUser";
import { useSnackbar } from "notistack";

interface Props {
  token: string;
  emergencyKey: string;
  ssoId: string;
}

// interface Session {
//   accessToken: string;
//   emergencyKey: string;
//   symKey: CryptoKey;
//   ssoId: string;
// }

const PatientDetails = (props: Props) => {
  const [data, setData] = React.useState<any | null>(null);
  const [importSession, setImportSession] = React.useState<string | null>(null);
  const session = useUserSession(props);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    console.log(session);
    if (!props.token || !props.emergencyKey) return;
    if (!session) return;
    fetch(`${API}/patient/rgpd`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    }).then(async (res) => {
      if (!res.ok) {
        alert(res.statusText);
        localStorage.clear();
        window.location.reload();
        return;
      }

      const json = await res.json();
      setData(await Crypto.UnprotectObjectFromServer(json, session.symKey));
    });
  }, [session, props]);

  if (!session) return <p>Création d'une session sécurisée...</p>;
  if (!data) return <p>Chargement des données de l'utilisateur...</p>;

  return (
    <div>
      <h2>Données brutes</h2>
      <p>{JSON.stringify(data)}</p>
      <h2>Actions</h2>
      <Button
        disabled={!!importSession}
        onClick={() =>
          createPatientFakeSession(session).then((res) => setImportSession(res))
        }
      >
        Créer une session sur l'app
      </Button>
      {importSession}
      <EnableDisableUser token={props.token} ssoId={props.ssoId} />
      <Button
        onClick={() => {
          fetch(`${PROTO}/patient/reset-protocol/${props.ssoId}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${props.token}`,
            },
          })
            .then((res) => {
              if (!res.ok) {
                enqueueSnackbar(
                  "Erreur lors de la réinitialisation du protocole",
                  { variant: "error" }
                );
              } else {
                enqueueSnackbar("Protocole réinitialisé", {
                  variant: "success",
                });
              }
            })
            .catch(() => {
              enqueueSnackbar(
                "Erreur lors de la réinitialisation du protocole",
                { variant: "error" }
              );
            });
        }}
      >
        Réinitialiser le protocole
      </Button>
    </div>
  );
};

export default PatientDetails;
