import React from "react";
import { Button } from "@mui/material";
import { SSO } from "./const";

interface Props {
  token: string;
  ssoId: string;
}

const EnableDisableUser = (props: Props) => {
  return (
    <div>
      <Button
        onClick={async () => {
          const res = await fetch(
            `${SSO}/sso/superadmin/enable/${props.ssoId}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${props.token}`,
              },
            }
          );
          if (!res.ok) alert(res.statusText);
        }}
      >
        Activer le compte
      </Button>
      <Button
        onClick={async () => {
          const res = await fetch(
            `${SSO}/sso/superadmin/disable/${props.ssoId}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${props.token}`,
              },
            }
          );
          if (!res.ok) alert(res.statusText);
        }}
      >
        Désactiver le compte
      </Button>
    </div>
  );
};

export default EnableDisableUser;
